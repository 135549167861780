export const PROJECTS = [

  {
    id: '30',
    title: 'BarPrep-Pro',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1736519325/barpro_ax560h.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1736519325/barpro_ax560h.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      // 'TYPESCRIPT',
      // 'CSS',
      // 'REACT JS',
      'On going Development',
    ],
  },

  {
    id: '29',
    title: 'Countdown',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1736237928/countdown_dcdsxs.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1736237928/countdown_dcdsxs.png',
    },
    links: {
      site: 'https://countdown-co.prodweb.site/',
      repo: '',
    },
    technologies: [
      'TYPESCRIPT',
      'CSS',
      'REACT JS',
    ],
  },

  {
    id: '28',
    title: 'Reservation System',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1736237924/reservation_n1vjaa.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1736237924/reservation_n1vjaa.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      'PHP',
      'JAVASCRIPT',
      'AJAX',
      'CSS',
    ],
  },

  {
    id: '27',
    title: 'Skill Fusion Co.',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1736237425/skillfusion_mj84qo.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1736237425/skillfusion_mj84qo.png',
    },
    links: {
      site: 'https://skillfusion-co.prodweb.site/',
      repo: '',
    },
    technologies: [
      '#React JS',
      '#Typescript',
      '#CSS',
      'SCSS',
    ],
  },

  {
    id: '26',
    title: 'Kiosk',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1736237933/kiosk_idrsxt.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1736237933/kiosk_idrsxt.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
      'Kiosk Mode Type',
    ],
  },

  {
    id: '25',
    title: 'E-Compendium',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1725914331/hotel_directory_qq1gf7.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1725914331/hotel_directory_qq1gf7.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
    ],
  },

  {
    id: '24',
    title: 'Kape Balai',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1724606886/kape_e2gqok.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1724606886/kape_e2gqok.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      // '#CSS',
      // '#Html',
      // '#Javascript',
      // '#Laravel',
      // '#Vue JS',
      // '#MYSQL',
      // '#Postman',
      // '#Ajax',
      // '#Blade',
      // '#Livewire',
      // '#PHP',

      'On going Development',
      
     
    ],
  },

  {
    id: '23',
    title: 'LMS Quiz',
    
    image: {
      src: '',
      placeholderSrc:
        '',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      // '#CSS',
      // '#Html',
      // '#Javascript',
      // '#Laravel',
      // '#Vue JS',
      // '#MYSQL',
      // '#Postman',
      // '#Ajax',
      // '#Blade',
      // '#Livewire',
      // '#PHP',

      'On going Development',
      
    ],
  },

  {
    id: '22',
    title: 'Learn at Leisure',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1718390608/lms_thlhf9.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1718390608/lms_thlhf9.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      // '#CSS',
      // '#Html',
      // '#Javascript',
      // '#Laravel',
      // '#Vue JS',
      // '#MYSQL',
      // '#Postman',
      // '#Ajax',
      // '#Blade',
      // '#Livewire',
      // '#PHP',

      'On going Development',
      
     
    ],
  },

  {
    id: '21',
    title: 'Property Inventory',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1720471096/property_tag_system_tvpffv.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1720471096/property_tag_system_tvpffv.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
      '#Laravel',
      '#MYSQL',
      '#Blade',
      '#PHP',
    ],
  },


  {
    id: '20',
    title: 'Hotel Booking Mobile App',
    
    image: {
      src: '',
      placeholderSrc:
        '',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#Dart',
      '#Flutter',
      '#MYSQL',
      '#POSTMAN',
      '#Ajax',
      '#Laravel',
      '#Livewire',
      '#PHP',
     
    ],
  },

  {
    id: '19',
    title: 'JC Dental Center',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1718390150/jc_dental_euuglv.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1718390150/jc_dental_euuglv.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
      '#Vue JS',
      '#MYSQL',
      '#Postman',
      '#Ajax',
      '#Laravel',
      '#PHP',
     
    ],
  },

  {
    id: '18',
    title: 'Panpacific University Inventory System',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1718389051/panpacific_university_fbh64b.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1718389051/panpacific_university_fbh64b.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
      '#PHP',
      '#Laravel',
      '#MYSQL',
     
    ],
  },

  
  {
    id: '17',
    title: 'Earth Hour',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1710613417/EarthHour_tjmjce.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1710613417/EarthHour_tjmjce.png',
    },
    links: {
      site: 'https://prodweb.site/Earth%20Hour/',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
     
    ],
  },

  {
    id: '16',
    title: 'Easter Tradition',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1710613337/easter_tradition_es3inm.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1710613337/easter_tradition_es3inm.png',
    },
    links: {
      site: 'https://prodweb.site/EasterTradition/',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
     
    ],
  },

  {
    id: '15',
    title: 'Music Room',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1710613526/Music_File_h6w6za.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1710613526/Music_File_h6w6za.png',
    },
    links: {
      site: 'https://prodweb.site/MusicFileSystem/',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
      '#Laravel',
      '#PHP',
    ],
  },

  {
    id: '14',
    title: 'Helipad',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1708975730/helipad_landing_page_v3lwqj.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1708975730/helipad_landing_page_v3lwqj.png',
    },
    links: {
      site: 'https://prodweb.site/Helipad/',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
    ],

  },

  {
    id: '13',
    title: 'Chinese New Year (Landing Page)',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1708975409/chinese_landing_page_ntyhm5.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1708975409/chinese_landing_page_ntyhm5.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Javascript',
    ],

  },

  {
    id: '12',
    title: 'Hippie Soul Hatters',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1702819043/hippiesoulhatters_olag5r.jpg',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1702819043/hippiesoulhatters_olag5r.jpg',
    },
    links: {
      site: 'https://hippiesoulhatters.com/',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Php',
      '#Javascript',
    ],

  },

  {
    id: '11',
    title: 'Greenbox Maintenance',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1710613796/greenbox_fwwkfb.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1710613796/greenbox_fwwkfb.png',
    },
    links: {
      site: 'http://greenboxmaintenance.com/',
      repo: '',
    },
    technologies: [

      '#CSS',
      '#Html',
      '#Php',
      '#Javascript',
      '#Laravel',
    
    ],

  },

  {
    id: '10',
    title: 'Enviromental, Health, Safety, Security',
  
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1692622287/EHSS_gzlg60.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1692622287/EHSS_gzlg60.png',
    },
    links: {
      site: 'http://vbehs.com/',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Php',
      '#Laravel',
      '#Javascript',
      '#Ajax',
      '#Bootstrap',
      '#Mysql',
      '#VueJS',
    ],


  },

  {
    id: '9',
    title: 'VBIS Management System',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1692621885/ITSystem_se5hto.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1692621885/ITSystem_se5hto.png',
    },
    
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#Laravel',
      '#Php',
      '#CSS',
      '#HTML',
      '#Bootstrap',
      '#Mysql',
      '#Javacsript',
      '#Ajax',
    ],
    
    
  },


  {
    id: '8',
    title: 'On The Job Management System',
   
    image: {
      src: '',
      placeholderSrc:
        '',
    },
    links: {
      site: '',
    },

    technologies: [
      '#CSS',
      '#Javascript',
      '#PHP',
      '#Mysql',
      '#Ajax',
      '#VueJS',
    ],
    
    technologies: [
      'The OJTMS is currently working for version 2 ',
     
    ],

  },

  {
    id: '7',
    title: 'Procurement System',
   
    image: {
      src: '',
      placeholderSrc:
        '',
    },
    links: {
      site: '',
    },
    technologies: [
      '#Laravel',
      '#Php',
      '#CSS',
      '#HTML',
      '#Mysql',
      '#Javacsript',
      '#Ajax',
    ],
  },

  {
    id: '6',
    title: 'Facility System',
   
    image: {
      src: '',
      placeholderSrc:
        '',
    },
    links: {
      site: '',
    },
    technologies: [
      '#Laravel',
      '#Php',
      '#CSS',
      '#HTML',
      '#Mysql',
      '#Javacsript',
      '#Ajax',
    ],
  },

  {
    id: '5',
    title: 'Employee Shift Monitoring System',
   
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1674555901/ESMS_wxr9wd.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1674555901/ESMS_wxr9wd.png',
    },

    links: {
      site: '',
    },

    technologies: [
      '#Laravel',
      '#Php',
      '#CSS',
      '#HTML',
      '#Mysql',
      '#Javacsript',
      '#Ajax',
    ],
    
  },

  {
    id: '4',
    title: 'James Wright',
   
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1674555971/JWwebsite_nscmmo.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1674555971/JWwebsite_nscmmo.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      '#Html',
      '#Php',
      '#Laravel',
      '#Javascript',
      '#Ajax',
      '#Bootstrap',
      '#Mysql',
    ],
  },
  {
    id: '3',
    title: 'James Wright Menu',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1674564266/James_Wright_Menu_wbxrqx.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1674564266/James_Wright_Menu_wbxrqx.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#Javascript',
      '#Ajax',
      '#Php',
      '#Mysql',
    ],
  },
  {
    id: '2',
    title: 'Online Shop',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1681654730/online_shop_bx1qpm.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1681654730/online_shop_bx1qpm.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      'Javascript',
      '#Blade',
      '#PHP',
      '#Mysql',
      '#Laravel',
      '#SCSS',
    ],
  },

  {
    id: '1',
    title: 'UB ARC',
    
    image: {
      src: 'https://res.cloudinary.com/duu2wavig/image/upload/v1681654734/UBARC_ahoabo.png',
      placeholderSrc:
        'https://res.cloudinary.com/duu2wavig/image/upload/v1681654734/UBARC_ahoabo.png',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#CSS',
      'Javascript',
      '#PHP',
      '#Mysql',
      '#Ajax',
    ],
  },



];
